import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Card from "../shared/Card"
import { orgAcronym } from "../../constants"

export default function Stories() {
  const data = useStaticQuery(graphql`
    {
      allPrismicBlogPost(sort: { fields: data___release_date, order: DESC }) {
        nodes {
          id
          uid
          tags
          data {
            title {
              text
            }
            release_date(formatString: "MMMM DD YYYY")
            author {
              document {
                ... on PrismicAuthor {
                  id
                  data {
                    name {
                      text
                    }
                  }
                  dataString
                }
              }
            }
            featured_image {
              alt
              localFile {
                childImageSharp {
                  fluid(maxWidth: 306, maxHeight: 200) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const {
    allPrismicBlogPost: { nodes: rawPosts },
  } = data

  let posts = []

  if (rawPosts != null) {
    posts = rawPosts.map(rawPost => {
      const {
        id,
        tags,
        uid: slug,
        data: {
          title: { text: title },
          release_date: createdAt,
          author: {
            document: {
              data: {
                name: { text: author },
              },
            },
          },
          featured_image: {
            alt: featuredImageAltText,
            localFile: {
              childImageSharp: { fluid: featuredImage },
            },
          },
        },
      } = rawPost

      return {
        id,
        tags,
        slug,
        title,
        author,
        createdAt,
        featuredImage,
        featuredImageAltText,
      }
    })
  }

  return (
    <div className="stories-container">
      <h4 id="podcasts">Listen to us speak about stuff</h4>
      <h3>
        {orgAcronym} Podcast is here to keep you company
        <span className="period">.</span>
      </h3>
      <div className="podcasts-container">
        <p>Ganbei is a podcast dedicated to amplifying the voices of Asian professionals as they explore the intricacies of growing up: careers, relationships, family, and everything else. Join us for a round as we hear stories from leaders, innovators, and trail blazers from the Asian community and celebrate their past, present, and future. <br/><b>Support the podcast</b>: <a href="https://anchor.fm/ganbeipodcast/support">https://anchor.fm/ganbeipodcast/support</a>
        </p>
        <iframe src="https://open.spotify.com/embed/show/6D3WMoA8BF1faP8Rktwrf4" width="100%" height="232" frameborder="0" allowtransparency="true" allow="encrypted-media" />
      </div>

      <h4 className="pt-16" id="blogs">Successes, challenges, learnings - we’re all about sharing</h4>
      <h3>
        Latest posts<span className="period">.</span>
      </h3>
      <div className="w-full mt-12 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
        {posts.map((node, i) => {
          return (
            <Link key={node.id} to={node.slug}>
              <Card
                title={node.title}
                top={node.author}
                subtitle={node.createdAt}
                image={{
                  fluidImg: node.featuredImage,
                  imgAlt: node.featuredImageAltText,
                }}
                tags={node.tags}
                bottomRight={`${posts.length < 10 ? "0" : ""}${
                  posts.length - i
                }`}
              />
            </Link>
          )
        })}
      </div>
    </div>
  )
}
